<template>
  <div class="trame" v-if="rdvAac == false">
    <div id="trameHeader" class="pt-5">
      <h1>Eric Dupont</h1>
      <Button id="btnTerminer" @click="endCours">
        <template v-if="formation">
          <template v-if="appActu == null">
            Terminer le cours
          </template>
          <template v-else>
            Terminer le {{ apActu }}
          </template>
          
        </template>
        <template v-else>Terminer le cours</template>
      </Button>
      <template v-if="checkAac">
        <Button v-if="!formation" id="btnTerminer" @click="finFormation"
          >Fin de Formation</Button
        >
      </template>
      <template v-if="canTakeRdvp">
        <button class="btn btn-primary" @click="this.rdvAac = true;">
          {{apNext}}
        </button>
      </template>
      <template v-else>
        <button class="btn btn-secondary" @click="this.rdvAac = true;" disabled>
          {{apNext}}
        </button>
      </template>


      <router-link to="/PlanningMonoFront"
        ><Button id="btnTerminer">Retourner au planning</Button></router-link
      >
    </div>

    <template v-if="findTrame">
      <br>
      <br>
      <br>
      <h1>Vous n'avez pas de trame liée à votre agence ou à ce type de véhicule (boîte auto ou manuelle)</h1>
      <br>
      <br>
      <br>
    </template>
    <template v-else>
      <h4>{{ this.latrame.nom }}</h4>

      <table class="trame-update">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>Traité</th>
            <th>Assimilé</th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(competence, index) in trame['competences']"
            :key="index"
          >
            <template v-for="(objectif, i) in competence['objectifs']" :key="i">
              <tr>
                <th
                  v-bind:style="{ 'background-color': competence.couleur }"
                ></th>
                <td id="objTitre">{{ objectif.label }}</td>
                <td>{{ objectif.duree }}</td>
                <template v-if="objectif.objacq == 'traite'">
                  <td>
                    <label class="switch"
                      ><input
                        @click="actionObj"
                        :data-id="objectif.id"
                        type="checkbox"
                        name="traite"
                        checked /><span class="slider"></span
                    ></label>
                  </td>
                  <td>
                    <label class="switch"
                      ><input
                        @click="actionObj"
                        :data-id="objectif.id"
                        type="checkbox"
                        name="assimile" /><span class="slider"></span
                    ></label>
                  </td>
                </template>
                <template v-else-if="objectif.objacq === 'assimile'">
                  <td>
                    <label class="switch"
                      ><input
                        @click="actionObj"
                        :data-id="objectif.id"
                        type="checkbox"
                        name="traite"
                        checked /><span class="slider"></span
                    ></label>
                  </td>
                  <td>
                    <label class="switch"
                      ><input
                        @click="actionObj"
                        :data-id="objectif.id"
                        type="checkbox"
                        name="assimile"
                        checked /><span class="slider"></span
                    ></label>
                  </td>
                </template>
                <template v-else>
                  <td>
                    <label class="switch"
                      ><input
                        @click="actionObj"
                        :data-id="objectif.id"
                        type="checkbox"
                        name="traite" /><span class="slider"></span
                    ></label>
                  </td>
                  <td>
                    <label class="switch"
                      ><input
                        @click="actionObj"
                        :data-id="objectif.id"
                        type="checkbox"
                        name="assimile" /><span class="slider"></span
                    ></label>
                  </td>
                </template>
              </tr>
              <template
                v-for="(sousObjectif, idx) in objectif['sousObjectifs']"
                :key="idx"
              >
                <tr>
                  <th
                    v-bind:style="{ 'background-color': competence.couleur }"
                  ></th>
                  <td id="labelSousObj">{{ sousObjectif.label }}</td>
                  <td>{{ sousObjectif.duree }}</td>

                  <template v-if="objectif.objacq == 'traite'">
                    <td>
                      <label class="switch"
                        ><input
                          id="ssobj"
                          @click="actionSousObjectif"
                          :data-id="sousObjectif.id"
                          :data-obj-id="objectif.id"
                          type="checkbox"
                          name="traite"
                          checked /><span class="slider"></span
                      ></label>
                    </td>
                    <td>
                      <label class="switch"
                        ><input
                          id="ssobj"
                          @click="actionSousObjectif"
                          :data-id="sousObjectif.id"
                          :data-obj-id="objectif.id"
                          type="checkbox"
                          name="assimile" /><span class="slider"></span
                      ></label>
                    </td>
                  </template>
                  <template v-else-if="objectif.objacq === 'assimile'">
                    <td>
                      <label class="switch"
                        ><input
                          id="ssobj"
                          @click="actionSousObjectif"
                          :data-id="sousObjectif.id"
                          :data-obj-id="objectif.id"
                          type="checkbox"
                          name="traite"
                          checked /><span class="slider"></span
                      ></label>
                    </td>
                    <td>
                      <label class="switch"
                        ><input
                          id="ssobj"
                          @click="actionSousObjectif"
                          :data-id="sousObjectif.id"
                          :data-obj-id="objectif.id"
                          type="checkbox"
                          name="assimile"
                          checked /><span class="slider"></span
                      ></label>
                    </td>
                  </template>
                  <template v-else>
                    <template v-if="sousObjectif.acquis == 'null'">
                      <td>
                        <label class="switch"
                          ><input
                            id="ssobj"
                            @click="actionSousObjectif"
                            :data-id="sousObjectif.id"
                            :data-obj-id="objectif.id"
                            type="checkbox"
                            name="traite" /><span class="slider"></span
                        ></label>
                      </td>
                      <td>
                        <label class="switch"
                          ><input
                            id="ssobj"
                            @click="actionSousObjectif"
                            :data-id="sousObjectif.id"
                            :data-obj-id="objectif.id"
                            type="checkbox"
                            name="assimile" /><span class="slider"></span
                        ></label>
                      </td>
                    </template>
                    <template v-else>
                      <template v-if="sousObjectif.acquis[0].id == 1">
                        <td>
                          <label class="switch"
                            ><input
                              id="ssobj"
                              @click="actionSousObjectif"
                              :data-id="sousObjectif.id"
                              :data-obj-id="objectif.id"
                              type="checkbox"
                              name="traite"
                              checked /><span class="slider"></span
                          ></label>
                        </td>
                        <td>
                          <label class="switch"
                            ><input
                              id="ssobj"
                              @click="actionSousObjectif"
                              :data-id="sousObjectif.id"
                              :data-obj-id="objectif.id"
                              type="checkbox"
                              name="assimile" /><span class="slider"></span
                          ></label>
                        </td>
                      </template>
                      <template v-else>
                        <td>
                          <label class="switch"
                            ><input
                              id="ssobj"
                              @click="actionSousObjectif"
                              :data-id="sousObjectif.id"
                              :data-obj-id="objectif.id"
                              type="checkbox"
                              name="traite"
                              checked /><span class="slider"></span
                          ></label>
                        </td>
                        <td>
                          <label class="switch"
                            ><input
                              id="ssobj"
                              @click="actionSousObjectif"
                              :data-id="sousObjectif.id"
                              :data-obj-id="objectif.id"
                              type="checkbox"
                              name="assimile"
                              checked /><span class="slider"></span
                          ></label>
                        </td>
                      </template>
                    </template>
                  </template>
                </tr>
              </template>
            </template>
            <br />
            <br />
          </template>
        </tbody>
      </table>
    </template>


  </div>
  <div v-else>
    <RdvAac :apNext="apNext" @goBackToTrame="goBackToTrame" @endCours="endCours"/>
  </div>
</template>

<script>
import RdvAac from "@/components/formationAac/RdvAac.vue";
export default {
  name: "Trame",
  components: {
    RdvAac,
  },

  data() {
    return {
      trame: [],
      latrame: [],
      checkAac: false,
      formation: false,
      apActu : "",
      apNext : "",
      rdvAac : false,
      canTakeRdvp : false,
      findTrame : false,
    };
  },

  mounted() {
    this.getTrame();
    this.getCheckAac();
  },
  methods: {

    goBackToTrame(){
      this.rdvAac = false;
    },

    finFormation() {
      let now = new Date();
      now.setHours(now.getHours() + 1);
      window.api
        .post(
          "formation_aacs",
          {
            eleve: "api/utilisateurs/" + this.$route.params.id,
            dateFin: now,
            rdvp: false,
            rdvp1: false,
            rdvp2: false,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(() => {
          this.endCours();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    actionObj(e) {
      let cbxs = document.querySelectorAll("#ssobj");

      cbxs.forEach((cbx) => {
        if (e.target.name == cbx.name) {
          let lessobj = false;
          this.trame.competences.forEach((comp) => {
            comp.objectifs.forEach((obj) => {
              obj.sousObjectifs.forEach((ssobj) => {
                if (
                  e.target.attributes[0].nodeValue ==
                    cbx.attributes[2].nodeValue &&
                  cbx.attributes[1].nodeValue == ssobj.id
                ) {
                  lessobj = ssobj;
                }
              });
            });
          });

          if (lessobj != false) {
            if (e.target.name == "traite") {
              if (e.target.checked) {
                if (lessobj.acquis == "null") {
                  window.api
                    .post(
                      "acquisitions",
                      {
                        etatAcquisition: "api/etat_acquisitions/1",
                        sousObjectif:
                          "api/sous_objectifs/" + cbx.attributes[1].nodeValue,
                        eleve: "api/utilisateurs/" + this.$route.params.id,
                      },
                      {
                        headers: {
                          Authorization: "Bearer " + this.$store.state.token,
                        },
                      }
                    )
                    .then(() => {
                      this.getTrame();
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                } else {
                  window.api
                    .put(
                      "acquisitions/" + lessobj.acquis[0].idacq,
                      {
                        etatAcquisition: "api/etat_acquisitions/1",
                        sousObjectif:
                          "api/sous_objectifs/" + cbx.attributes[1].nodeValue,
                        eleve: "api/utilisateurs/" + this.$route.params.id,
                      },
                      {
                        headers: {
                          Authorization: "Bearer " + this.$store.state.token,
                        },
                      }
                    )
                    .then(() => {
                      this.getTrame();
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              } else {
                window.api
                  .delete("acquisitions/" + lessobj.acquis[0].idacq, {
                    headers: {
                      Authorization: "Bearer " + this.$store.state.token,
                    },
                  })
                  .then(() => {
                    this.getTrame();
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            } else {
              if (e.target.checked) {
                if (lessobj.acquis == "null") {
                  window.api
                    .post(
                      "acquisitions",
                      {
                        etatAcquisition: "api/etat_acquisitions/2",
                        sousObjectif:
                          "api/sous_objectifs/" + cbx.attributes[1].nodeValue,
                        eleve: "api/utilisateurs/" + this.$route.params.id,
                      },
                      {
                        headers: {
                          Authorization: "Bearer " + this.$store.state.token,
                        },
                      }
                    )
                    .then(() => {
                      this.getTrame();
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                } else {
                  window.api
                    .put(
                      "acquisitions/" + lessobj.acquis[0].idacq,
                      {
                        etatAcquisition: "api/etat_acquisitions/2",
                        sousObjectif:
                          "api/sous_objectifs/" + cbx.attributes[1].nodeValue,
                        eleve: "api/utilisateurs/" + this.$route.params.id,
                      },
                      {
                        headers: {
                          Authorization: "Bearer " + this.$store.state.token,
                        },
                      }
                    )
                    .then(() => {
                      this.getTrame();
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              } else {
                window.api
                  .put(
                    "acquisitions/" + lessobj.acquis[0].idacq,
                    {
                      etatAcquisition: "api/etat_acquisitions/1",
                      sousObjectif:
                        "api/sous_objectifs/" + cbx.attributes[1].nodeValue,
                      eleve: "api/utilisateurs/" + this.$route.params.id,
                    },
                    {
                      headers: {
                        Authorization: "Bearer " + this.$store.state.token,
                      },
                    }
                  )
                  .then(() => {
                    this.getTrame();
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            }
          }
        }
      });
    },

    actionSousObjectif(e) {
      let lessobj;

      this.trame.competences.forEach((comp) => {
        comp.objectifs.forEach((obj) => {
          obj.sousObjectifs.forEach((ssObj) => {
            if (e.target.attributes[1].nodeValue == ssObj.id) {
              lessobj = ssObj;
            }
          });
        });
      });
      if (e.target.name == "traite") {
        if (e.target.checked == true) {
          if (lessobj.acquis == "null") {
            window.api
              .post(
                "acquisitions",
                {
                  etatAcquisition: "api/etat_acquisitions/1",
                  sousObjectif:
                    "api/sous_objectifs/" + e.target.attributes[1].nodeValue,
                  eleve: "api/utilisateurs/" + this.$route.params.id,
                },
                {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                  },
                }
              )
              .then(() => {
                this.getTrame();
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            window.api
              .put(
                "acquisitions/" + lessobj.acquis[0].idacq,
                {
                  etatAcquisition: "api/etat_acquisitions/1",
                  sousObjectif:
                    "api/sous_objectifs/" + e.target.attributes[1].nodeValue,
                  eleve: "api/utilisateurs/" + this.$route.params.id,
                },
                {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                  },
                }
              )
              .then(() => {
                this.getTrame();
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } else {
          window.api
            .delete("acquisitions/" + lessobj.acquis[0].idacq, {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            })
            .then(() => {
              this.getTrame();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        if (e.target.checked) {
          if (lessobj.acquis == "null") {
            window.api
              .post(
                "acquisitions",
                {
                  etatAcquisition: "api/etat_acquisitions/2",
                  sousObjectif:
                    "api/sous_objectifs/" + e.target.attributes[1].nodeValue,
                  eleve: "api/utilisateurs/" + this.$route.params.id,
                },
                {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                  },
                }
              )
              .then(() => {
                this.getTrame();
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            window.api
              .put(
                "acquisitions/" + lessobj.acquis[0].idacq,
                {
                  etatAcquisition: "api/etat_acquisitions/2",
                  sousObjectif:
                    "api/sous_objectifs/" + e.target.attributes[1].nodeValue,
                  eleve: "api/utilisateurs/" + this.$route.params.id,
                },
                {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                  },
                }
              )
              .then(() => {
                this.getTrame();
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } else {
          window.api
            .put(
              "acquisitions/" + lessobj.acquis[0].idacq,
              {
                etatAcquisition: "api/etat_acquisitions/1",
                sousObjectif:
                  "api/sous_objectifs/" + e.target.attributes[1].nodeValue,
                eleve: "api/utilisateurs/" + this.$route.params.id,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then(() => {
              this.getTrame();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },

    getTrame() {
      window.api
        .get("/trame/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          if(response.data.trame == false){
            this.findTrame = true;
          }{
            this.trame = response.data.trame[0];
            this.latrame = response.data.latrame;
          }
          
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCheckAac() {
      window.api
        .get("/checkAac/" + this.$route.params.id+"/"+ this.$route.params.rdvId, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.formation = response.data.formation;
          this.checkAac = response.data.utilisateur;
          this.apActu = response.data.apActu;
          this.apNext = response.data.apNext;
          this.canTakeRdvp = response.data.canTakeRdvp;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    endCours() {
      let now = new Date();
      let confirmCancel = confirm(
        "Etes-vous sur de vouloir terminer le cours ? "
      );

      if (confirmCancel) {
        now.setHours(now.getHours() + 1);
        window.api
          .patch(
            "/apointments/" + this.$route.params.rdvId,
            {
              doneDate: now,
              done: true,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {
            this.$router.push("/PlanningMonoFront");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
table.trame-update {
  width: 90%;
  margin: auto;
}

.trame {
  background: url("../../assets/images/fond2.png");
  width: 100vw;
  min-height: 100vh;
  height: auto;
  background-size: cover;
  background-position: center, center;
}
table.trame-update thead {
  color: black;
}

#objTitre {
  font-size: 1.4em;
  font-weight: bold;
  text-align: start;
}
#labelSousObj {
  padding-left: 50px;
  text-align: start;
  font-weight: bold;
}

#trameHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 8%;
}

#btnTerminer {
  background-color: #07989f;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

input:checked + .slider {
  background-color: #07989f;
}

input:focus + .slider {
  box-shadow: 0 0 1px #07989f;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
