<template>
    <div class="background">
        <Toast />
        <div class="contentPage">
            <div class="header">
                <div class="goHome">
                    <img src="../../assets/images/logoMonPermis.png" @click="getBackToHome" alt="">
                </div>
                <div class="title">
                    <h1>Transfert d'élève</h1>
                </div>
            </div>

            <div class="content">
                <div class="inscEleve">
                    <card style="width: 100%;  height: 550px;" class="moniteurList">
                        <template #content>
                            <h3>Création du compte</h3>
                            <div class="name">
                                <div class="row">
                                    <div class="field col-md-6">
                                        <input
                                            type="text"
                                            id="lnom"
                                            name="nom"
                                            placeholder=" "
                                            v-model="eleve.nom"
                                            required
                                        />
                                        <label class="labelForm">Nom élève</label>
                                    </div>
                                    <div class="field col-md-6">
                                        <input
                                            type="text"
                                            id="lpnom"
                                            name="pnom"
                                            placeholder=" "
                                            v-model="eleve.prenom"
                                            required
                                        />
                                        <label class="labelForm">Prénom élève</label>
                                    </div>
                                </div>
                            
                            </div>
                            <div class="info">
                                <div class="row">
                                    <div class="field col-md-6">
                                        <input
                                            type="text"
                                            id="lnom"
                                            name="nom"
                                            placeholder=" "
                                            v-model="eleve.mail"
                                            required
                                        />
                                        <label class="labelForm">Mail élève</label>
                                    </div>
                                    <div class="field col-md-6">
                                        <input
                                            type="text"
                                            id="lpnom"
                                            name="pnom"
                                            placeholder=" "
                                            v-model="eleve.tel"
                                            required
                                        />
                                        <label class="labelForm">Téléphone élève</label>
                                    </div>
                                </div>

                                <select name="boite" id="boite" v-model="eleve.boite" class="boiteSelect">
                                    <option :value="null">Type de boite</option>
                                    <option value="bm" @click="setBoitePrice">BM</option>
                                    <option value="ba" @click="setBoitePrice">BA</option>
                                </select>
                                
                                <div class="row selectForfait">
                                    <div class="field col-md-6">
                                        <select name="transfert" id="transfert" v-model="eleve.transfert">
                                            <option :value="null">L'élève est-il transfert de l'ancien systeme ?</option>
                                            <option :value="true">Oui</option>
                                            <option :value="false">Non</option>
                                        </select>
                                    </div>
                                    <div class="field col-md-6">
                                        <select name="transfert" id="transfert" v-model="eleve.subv" v-if="eleve.transfert == true">
                                            <option :value="null">L'élève est-il subventionné par le CPF</option>
                                            <option :value="true">Oui</option>
                                            <option :value="false">Non</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </card>
                </div>

                <div class="forfait" v-if="eleve.transfert == true">
                    <card style="width: 100%;  height: 400px; overflow: scroll;" class="moniteurList">
                        <template #content>
                            <h3>Forfait</h3>
                            <div class="infoForfait">
                                <table>
                                    <tr>
                                        <th>Info</th>
                                        <th>Forfait</th>
                                    </tr>
                                    <tr>
                                        <td>nb H Initial</td>
                                        <td><input type="text" name="nbIni" id="nbIni" v-model="nbIni"></td>
                                    </tr>
                                    <tr>
                                        <td>nb H à date</td>
                                        <td><input type="text" name="nbIni" id="nbIni" v-model="nbDate"></td>
                                    </tr>
                                    <tr>
                                        <td>Conso H</td>
                                        <td>{{ consoH }}</td>
                                    </tr>
                                    <tr>
                                        <td>px H</td>
                                        <td><input type="text" name="pxH" id="pxH" v-model="pxH"></td>
                                    </tr>
                                </table>
                            </div>

                            <div class="soldeCalcul">
                                <table>
                                    <tr>
                                        <th>Info</th>
                                        <th>Forfait</th>
                                        <th>Paiement</th>
                                        <th>Acpte CPF</th>
                                        <th>Droits CPF</th>
                                        <th>Conso</th>
                                        <th>Solde</th>
                                        <th>champs</th>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td><input type="text" name="totalFofait" id="totalFofait" v-model="totalForfait"></td>
                                        <td><input type="text" name="totalPaiement" id="totalPaiement" v-model="totalPaiement"></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Dossier</td>
                                        <td>{{ dossierForfait }}</td>
                                        <td>{{ dossierPaiement }}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><input type="text" name="dossierNeph" id="dossierNeph" v-model="dossierNeph" placeholder="NEPH"></td>
                                    </tr>
                                    <tr>
                                        <td>Code</td>
                                        <td>{{ codeForfait }}</td>
                                        <td>{{ codePaiement }}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><input type="text" name="codeCodeId" id="codeCodeId" v-model="codeId" placeholder="ID prepacode"><input type="text" name="codePassword" id="codePassword" v-model="codePassword" placeholder="mot de passe prepacode"/></td>
                                    </tr>
                                    <tr>
                                        <td>Conduite</td>
                                        <td>{{ conduiteForfait }}</td>
                                        <td>{{ conduitePaiement }}</td>
                                        <td></td>
                                        <td></td>
                                        <td>{{ conduiteConso }}</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr v-if="eleve.subv == true">
                                        <td>CPF</td>
                                        <td></td>
                                        <td><input type="text" name="cpfPaiement" id="cpfPaiement" v-model="cpfPaiement"></td>
                                        <td><input type="text" name="cpfAcpte" id="cpfAcpte" v-model="cpfAcpte"></td>
                                        <td>{{ cpfDroit }}</td>
                                        <td></td>
                                        <td></td>
                                        <td><input type="text" name="cpfCode" id="cpfCode" v-model="cpfCode"></td>
                                    </tr>
                                    <tr>
                                        <td>Solde</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>{{ soldeMontant }}</td>
                                        <td></td>
                                    </tr>
                                </table>
                            </div>

                        </template>
                    </card>

                    
                </div>
                <br>
                <br>
                <button class="btn btn-primary" @click="createTransfertEleve" v-if="loadBtnPost == false">VALIDER</button>
                <button class="btn btn-primary" @click="createTransfertEleve" v-if="loadBtnPost == true" disabled><span><i class="fa fa-spinner fa-spin"></i>VALIDER</span></button>
            </div>

        </div>
    </div>
</template>

<script>

import { useToast } from "primevue/usetoast";

export default {
  props: [],

  components: {

  },

  data() {
    return {
        eleve: {
            nom: "",
            prenom: "",
            mail: "",
            tel: "",
            transfert: null,
            subv: null,
            boite: null
        },

        prix: {
            ba:0,
            bm:0
        },

        nbIni : 0,
        nbDate : 0,
        consoH : 0,
        pxH : 60,

        totalForfait : 0,
        totalPaiement : 0,
        dossierForfait : 0,
        dossierPaiement : 0,
        dossierNeph : "",
        codeForfait : 0,
        codePaiement : 0,
        codeId : "",
        codePassword : "",
        conduiteForfait : 0,
        conduitePaiement : 0,
        conduiteConso : 0,
        cpfPaiement : 0,
        cpfAcpte : 0,
        cpfDroit : 0,
        cpfCode : "",
        soldeMontant : 0,
        toast: useToast(),
        loadBtnPost : false,
    };
  },

  mounted() {
    this.getFonctionnalite();
  },

  watch: {
    nbIni: function () {
        this.pxH = this.conduiteForfait / this.nbIni;
        if(this.pxH == 0){
            this.pxH = 60;
        }
        this.conduiteConso = this.consoH * this.pxH;
        this.soldeMontant = this.conduitePaiement + this.cpfPaiement - this.conduiteConso;
    },

    nbDate: function () {
        this.consoH = this.nbIni - this.nbDate;
        this.conduiteConso = this.consoH * this.pxH;
        this.soldeMontant = this.conduitePaiement + this.cpfPaiement - this.conduiteConso;
    },

    pxH: function () {
        this.conduiteForfait = this.nbIni * this.pxH;
    },

    totalForfait: function () {
        this.consoH = this.nbIni - this.nbDate;
        this.conduiteConso = this.consoH * this.pxH;
        this.conduiteForfait = this.nbIni * this.pxH;
        this.dossierForfait = (this.totalForfait - this.conduiteForfait) / 2;
        this.codeForfait = (this.totalForfait - this.conduiteForfait) / 2;
        this.dossierPaiement = this.dossierForfait;
        this.codePaiement = this.codeForfait;
        this.soldeMontant = this.conduitePaiement + this.cpfPaiement - this.conduiteConso;
    },

    totalPaiement: function () {
        this.conduitePaiement = this.totalPaiement - this.dossierPaiement - this.codePaiement - this.cpfPaiement;
        this.soldeMontant = this.conduitePaiement + this.cpfPaiement - this.conduiteConso;
    },

    cpfPaiement: function () {

        if(this.cpfPaiement.toString().substring(0,1) == "0"){
            console.log("gergre")
            this.cpfPaiement = this.cpfPaiement.toString().substring(1);
        }

        this.conduitePaiement = this.totalPaiement - this.dossierPaiement - this.codePaiement - this.cpfPaiement;
        this.cpfDroit = this.cpfPaiement - this.cpfAcpte;
        if(this.cpfPaiement == ""){
            this.cpfPaiement = 0;
        }
        
        this.soldeMontant = parseInt(this.conduitePaiement) + parseInt(this.cpfPaiement) - parseInt(this.conduiteConso);
    },

    cpfAcpte: function () {
        this.cpfDroit = this.cpfPaiement - this.cpfAcpte;
        this.soldeMontant = parseInt(this.conduitePaiement) + parseInt(this.cpfPaiement) - parseInt(this.conduiteConso);
    },
  },

  methods: {

    createTransfertEleve(){
        this.loadBtnPost = true;
        if(this.eleve.nom != "" && this.eleve.prenom != "" && this.eleve.mail != "" && this.eleve.tel != "" && this.eleve.transfert != null && this.eleve.boite != null){
            if(this.eleve.transfert == false){
                this.dossierPaiement = "null";
                this.codePaiement = "null";
                this.soldeMontant = "null";
                this.cpfDroit = "null";
                this.cpfAcpte = "null";
                this.cpfCode = "null";
            }else{
                if(!this.eleve.subv){
                    this.cpfDroit = "null";
                    this.cpfAcpte = "null";
                    this.cpfCode = "null";
                }
            }

            window.api
            .post("/agence/"+this.$store.state.user.agence.id+"/transfert/eleve",
            {
                transfert: this.eleve.transfert,
                nom: this.eleve.nom,
                prenom: this.eleve.prenom,
                mail: this.eleve.mail,
                tel: this.eleve.tel,
                boite: this.eleve.boite,
                dossier: this.dossierPaiement,
                code : this.codePaiement,
                solde : this.soldeMontant,
                droitCpf : this.cpfDroit,
                acpteCpf : this.cpfAcpte,
                codeCpf : this.cpfCode,
                neph : this.dossierNeph,
                codeId : this.codeId,
                codePassword : this.codePassword

            },
            {
                headers: {
                Authorization: "Bearer " + this.$store.state.token,
                },
            }
            )
            .then((response) => {
                if(response.data != "Infos manquantes"){
                    this.toast.add({
                        severity: "success",
                        summary: "L'élève a bien été saisi !",
                        detail: "Il a reçu un mail avec ses identifiants de connexion",
                        life: 3000,
                    });


                    this.eleve.nom = "";
                    this.eleve.prenom = "";
                    this.eleve.mail = "";
                    this.eleve.tel = "";
                    this.eleve.transfert = null;
                    this.eleve.subv = null;
                    this.eleve.boite = null;

                    this.nbIni = 0;
                    this.nbDate = 0;
                    this.consoH = 0;
                    this.pxH = 60;

                    this.totalForfait = 0;
                    this.totalPaiement = 0;
                    this.dossierForfait = 0;
                    this.dossierPaiement = 0;
                    this.dossierNeph = "";
                    this.codeForfait = 0;
                    this.codePaiement = 0;
                    this.codeId = "";
                    this.codePassword = "";
                    this.conduiteForfait = 0;
                    this.conduiteConso = 0;
                    this.conduitePaiement = 0;
                    this.cpfAcpte = 0;
                    this.cpfDroit = 0;
                    this.cpfPaiement = 0;
                    this.cpfCode = "";
                    this.soldeMontant = 0;

                    this.loadBtnPost = false;

                }else{
                    alert("veuillez saisir toutes les informations");
                    this.loadBtnPost = false;
                }
            })
            .catch((error) => {
            console.log(error);
            });
        }else{
            alert("Veuillez saisir toutes les informations de l'élève");
            this.loadBtnPost = false;
        }
    },

    setBoitePrice(){
        if(this.eleve.boite == "bm"){
            this.pxH = this.prix.bm;
        }else if(this.eleve.boite == "ba"){
            this.pxH = this.prix.ba;
        }
    },

    getFonctionnalite() {
      window.api
        .get("/fonctionnalites/", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {

            response.data["hydra:member"].forEach(r => {
                if(r.label == "conduite BM"){
                    this.prix.bm = r.montant;
                }else if(r.label == "conduite BA"){
                    this.prix.ba = r.montant;
                }
            })


        })
        .catch((error) => {
          console.log(error);
        });
    },

    getBackToHome(){
        this.$router.push({name: 'Home'});
    },

    changeNbIni(){
        console.log("oui")
    },
  },
};
</script>

<style scoped>

select.boiteSelect{
    width: 100%;
    display: inline-block;
    border: solid 3px #e0e0e0;
    margin-bottom: 3%;
    color: #444;
    padding: 5px 5px 5px 5px;
    background: transparent;
    border-radius: 10px;
}

div.background{
    height: 100vh;
    width: 100%;
    background-image: url("../../assets/images/Fond_ordinateur.png");
    background-size: cover;
}

div.contentPage{
    padding: 2em;
}

div.header{
    
    justify-content: space-around;
}

div.header div.goHome{
    float: left;
}

div.header img{
    width: 80px;
}

div.header h1{
    font-weight: bold;
}

div.inscEleve .field {
  position: relative;
  display: flex;
  justify-content: center;
}

div.inscEleve .labelForm {
  position: absolute;
  pointer-events: none;
  left: 1.25rem !important;
  top: 22px !important;
  transition: 0.2s ease all;
  font-size: 20px !important;
  color: #444 !important;
}

div.inscEleve .field input:focus ~ label,
input:not(:placeholder-shown) ~ label {
  top: -20px !important;
  font-size: 12px !important;
}

div.inscEleve input {
  width: 100%;
}

div.inscEleve .text-field,
  text {
    position: relative;
    margin: 10px 2.5px 20px 2.5px;
  }

  div.inscEleve input {
    display: inline-block;
    border: solid 3px #e0e0e0;
    margin-bottom: 8%;
    color: #444;
    padding: 5px 5px 5px 5px;
    height: 75px;
    background: transparent;
    border-radius: 10px;
  }

  div.inscEleve div.selectForfait select{
    display: inline-block;
    border: solid 3px #e0e0e0;
    margin-bottom: 8%;
    color: #444;
    padding: 5px 5px 5px 5px;
    background: transparent;
    border-radius: 10px;
  }

  div.inscEleve input:focus {
    border: thin solid #ffffff;
    border-bottom: solid medium #ffffff;
    background: transparent;
  }

.box {
  background: transparent linear-gradient(299deg, #fbbb5b 0%, #de6fa8 100%) 0%
    0% no-repeat padding-box !important;
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
}

div.inscEleve{
    width: 70%;
    margin: auto;
    margin-top: 5em;
}

div.forfait{
    width: 70%;
    margin: auto;
    margin-top: 2em;
}

div.forfait input{
    border: solid 3px #e0e0e0;
    color: #444;
    padding: 0px 0px 0px 5px;
    background: transparent;
    border-radius: 10px;
}

div.forfait table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 2em;
}

div.forfait td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

div.forfait tr:nth-child(even) {
  background-color: #f5f5f557;
}

h3{
    font-weight: bold;
    text-align: left;
    margin-bottom: 2em;
}

</style>

