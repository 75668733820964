<template>
    <div>
      <FullCalendar ref="fullCalendar" :options="calendarOptions" />

      <Dialog
        v-model:visible="showDispoMod"
        modal
        header="Ajouter une disponibilité"
        :style="{ width: '50vw' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
      >
        <select name="lieu" id="lieu" v-model="lieu">
          <option value="">
            Veuillez choisir le lieu de votre disponibilité
          </option>
          <template v-for="l in lieuxDispo" :key="l.id">
            <option :value="l.id" :title="l.fulladresse">{{ l.nom }}</option>
          </template>
        </select>
        <br />
        <br />
        <template v-if="loadDispoEleveBtn == false">
          <button @click="setDispo()" class="btn btn-primary">VALIDER</button>
        </template>
        <template v-else>
          <button @click="setDispo()" class="btn btn-primary" disabled><span><i class="fa fa-spinner fa-spin"></i>VALIDER</span></button>
        </template>
        
      </Dialog>

    </div>
  </template>
  
  <script>
  import "@fullcalendar/core/vdom";
  import FullCalendar from "@fullcalendar/vue3";
  import dayGridPlugin from "@fullcalendar/daygrid";
  import timeGridPlugin from "@fullcalendar/timegrid";
  import interactionPlugin from "@fullcalendar/interaction";
  import moment from "moment";
  
  export default {
  
    props: [
      "eleve",
      "getAllEleves"
    ],
  
    components: {
      FullCalendar,
    },
  
    data() {
      return {
        loadDispoEleveBtn : false,
        lieuxDispo: [],
        lieu : "",
        showDispoMod : false,
        planningSelect : "",
        calendarOptions: {
          plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
          initialView: "timeGridWeek",
          nowIndicator: true,
          eventResizableFromStart: true,
          firstDay: 1,
          allDaySlot: false,
          slotMinTime: "07:00:00",
          slotMaxTime: "21:00:00",
          height: "auto",
          locale: "fr",
          selectable: true,
          timeZone: "local",
          eventClick: this.deleteEvent,
          headerToolbar: {
            start: "prev,next",
            center: "title",
            end: "timeGridWeek,timeGridDay",
          },  
          events: [],
          select: this.select,
          editable: true,
          selectAllow: this.selectAllow,
          eventChange: this.putDispoEleve,
        },
      };
    },
  
    mounted() {
      this.getLieuxEleve();
      if(this.$store.state.rdvBoite != null){
        this.calendarOptions.events = this.$store.state.rdvBoite;
      }
  
      if(this.moniteurEdits == null){
        this.calendarOptions.events = [];
      }
      this.eleve.dispos.forEach(d => {
        this.calendarOptions.events.push({
            id: d.id,
            title: "Dispo : "+d.lieu,
            start: moment(d.start.date).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(d.end.date).format("YYYY-MM-DDTHH:mm:ss"),
            recurring: "true",
            color: "blue",
            type: "dispo",
        });
      })
      
    },
  
    methods: {

      getLieuxEleve() {
        window.api
          .get("/eleve/lieux/" + this.eleve.id, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            response.data.lieux.forEach(l => {
              let findL = null;
              findL = this.lieuxDispo.find(e => e.id == l.id)
              if(findL == undefined){
                this.lieuxDispo.push(l);
              }
            })
          })
          .catch((error) => {
            console.log(error);
          });
      },

  
      deleteEvent(e){
        window.api
        .delete("/disponibilites/" + e.event["_def"].publicId, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then(() => {
          let events = [];
          this.calendarOptions.events.forEach(i => {
            if(i.id != e["event"]["_def"]["publicId"]){
              events.push(i);
            }
          })
          this.calendarOptions.events = events;
          this.$emit("getAllEleves");
        })
        .catch((error) => {
          console.log(error);
        });
      },
  
      select(e) {
        this.planningSelect = e;
        this.showDispoMod = true;
      },

      setDispo(){
        this.loadDispoEleveBtn = true;
        if(this.lieu == ""){
          alert("Veuillez saisir un lieu pour votre disponibilité");
          this.loadDispoEleveBtn = false;
        }else{
          window.api
          .post(
            "/disponibilites",
            {
              start: moment(this.planningSelect.startStr).format("YYYY-MM-DDTHH:mm:ss"),
              end: moment(this.planningSelect.endStr).format("YYYY-MM-DDTHH:mm:ss"),
              eleve: "api/utilisateurs/" + this.eleve.id,
              lieu: "api/lieus/" + this.lieu,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            let lieuNom = "";
            this.lieuxDispo.forEach(l => {
              if(l.id == this.lieu){
                lieuNom = l.nom;
              }
            })

            this.calendarOptions.events.push({
              id: response.data.id,
              title: "Dispo : "+ lieuNom,
              start: moment(this.planningSelect.startStr).format("YYYY-MM-DDTHH:mm:ss"),
              end: moment(this.planningSelect.endStr).format("YYYY-MM-DDTHH:mm:ss"),
              recurring: "true",
              color: "blue",
              type: "dispo",
          });
          this.$emit("getAllEleves");
          this.showDispoMod = false;
          this.loadDispoEleveBtn = false;
          this.lieu = "";
          })
          .catch((error) => {
            console.log(error);
          });
        }
      }

    },
  };
  </script>

<style scoped>

select{
  width: 100%;
  padding: 16px 20px;
  border: none;
  border-radius: 4px;
  margin-right: 4em;
  background-color: #f1f1f1;
}

</style>
  