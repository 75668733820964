<template>
  <div id="Index">
    <!-- As a link -->
    <nav id="navi" class="navbar navbar-light">
      <a class="navbar-brand" href="#">
        <img
          id="iconLogo"
          :src="require('../assets/images/logoMonPermis.png')"
          style="width: 15%"
      /></a>

      <div id="btnNavbar">
        <router-link to="/Inscription"
          ><a class="navbar-brand" href="#">
            <Button class="btnViolet">Inscription</Button></a
          ></router-link
        >
        <router-link to="/Connexion"
          ><a class="navbar-brand" href="#">
            <Button class="btnTurquoise">Connexion</Button></a
          ></router-link
        >
      </div>

    </nav>
    <header id="header">
      <img
        id="imgHeader"
        :src="require('../assets/images/imageAccueilModified2.png')"
      />
    </header>
    <div>
      <h2 id="titreBlue">
        <strong>Toutes les fonctionnalités se passent en ligne :</strong>
      </h2>
      <div id="etape">
        <div id="listeEtape">
          <ul>
            <li>Dossier d'inscription</li>
            <li>Tests de code</li>
            <li>Réservation des heures de conduite</li>
            <li>Réservation des examens de code et de conduite</li>
            <li>Édition de votre permis</li>
          </ul>
        </div>
        <div>
          <img id="imgMain" :src="require('../assets/images/main.png')" />
        </div>
        <div>
          <router-link to="/Inscription"
            ><Button class="btnViolet" id="btnInscriptionGratuit"
              >JE M'INSCRIS GRATUITEMENT</Button
            ></router-link
          >
        </div>
      </div>
      <h2 id="titreBlue"><strong>Nous vous proposons:</strong></h2>
      <p id="textForfait" class="mt-5">
        <strong
          >Le forfait dossier + code + 21h de conduite pour SEULEMENT 1 325 €
        </strong>
      </p>
      <div id="infoAgence">
        <div id="listeAgence">
          <h2 class="mt-5" style="padding: 1%"><strong>nos agences</strong></h2>
          <ul>
            <li>
              CER Garibaldi - 47 Avenue Félix Faure - 69003 <br />
              LYON 09 83 97 18 18 - cergaribaldi@gmail.com
            </li>
            <li>
              CER Saint-Fons - 12 Avenue Gabriel Peri - 69190 <br />
              SAINT-FONS 09 51 57 03 57 - cersaintfons69@gmail.com
            </li>
          </ul>
        </div>
        <div>
          <img id="tel" :src="require('../assets/images/tel.png')" />
        </div>
      </div>
    </div>

    <div>
      <img id="imgParcours" :src="require('../assets/images/parcours.png')" />
    </div>

    <Dialog
      :header="`Information !`"
      v-model:visible="this.displayAvertissement"
      :style="{ width: '50vw' }"
    >
      <div id="avertissement">
        <h2>
          Afin de pouvoir <strong>utiliser</strong> toutes les fonctionnalités
          de monpermis.fr, nous vous demandons de bien vouloir utiliser un autre
          navigateur que Firefox.
        </h2>
        <img
          class="mt-5"
          id="iconLogo"
          :src="require('../assets/images/logoMonPermis.png')"
          style="width: 30%"
        />
        <Button
          class="btnViolet mt-5"
          @click="this.displayAvertissement = false"
        >
          Je comprend</Button
        >
      </div>
    </Dialog>


    <Dialog v-model:visible="visible" modal header="Mise à jour de monpermis.fr" :style="{ width: '75vw' }">



      <p>
        Le site est actuellement indisponible, nous travaillons sur la mise en ligne d'une nouvelle version. Elle sera disponible très prochainement. 
      </p>

      <img
          id="iconWorkLogo"
          :src="require('../assets/images/logoMonPermis.png')"
          style="width: 12%"
        />

    </Dialog>

  </div>
</template>

<script>

import jwt_decode from "jwt-decode";

export default {
  name: "Home",

  data() {
    return {
      displayAvertissement: false,
      visible : false,
    };
  },
  components: {},

  mounted(){
    let token = localStorage.getItem("user_access");
    if(token != null){
      token = token.substring(1);
      token = token.substring(0, token.length - 1);
      let user = jwt_decode(token);
      let dateNow = Date.now();
      dateNow = new Date(dateNow);
      let dateExp = new Date(user.exp * 1000);

      if(dateExp > dateNow){
        this.$store.commit("setUser", user);
        this.$store.commit("setToken", token);
        this.$router.push("/home");
      }
    }

  },

  methods: {
    displayPopUp() {
      this.displayAvertissement = true;
    },
    track() {
      this.$gtag.pageview({ page_path: "/index" });

      this.$gtag.pageview("/index");

      this.$gtag.pageview(this.$route);
    },
  },
};
</script>

<style scoped>

img#iconWorkLogo{
  display: block;
  margin: auto;
}

#btnNavbar {
  display: flex;
  align-items: center;
}

#avertissement {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.btnViolet {
  background: #de6fa8;
  border-radius: 13px;
  border: none;
}

#avertissement > h2 {
  text-align: left;
}

#imgHeader {
  width: 100%;
  height: 80vh;
  max-width: 100%;
  max-height: 100%;
}

#tel {
  width: 40%;
}

a {
  text-decoration: none;
}

#textForfait {
  color: #de6fa8;
  font-size: 2em;
  font-weight: bold;
}

.btnTurquoise {
  background: #07989f;
  border-radius: 13px;
  border: none;
}
#listeAgence {
  width: 100%;
}

#listeAgence > h2 {
  color: #de6fa8;
  font-weight: bold;
  text-align: left;
  font-size: 2.5em;
  margin-left: 3%;
}

#infoAgence {
  display: flex;
  padding: 2%;
  flex-direction: row;
  background: rgb(7, 152, 159, 0.2);
}

.btnViolet {
  background: #de6fa8;
  border-radius: 13px;
  border: none;
}

#imgMain {
  width: 20vh;
  position: relative;
}

#imgParcours {
  width: 80%;
  margin-top: 3%;
}

#imgMoni {
  width: 100%;
  height: 100vh;
  margin-top: 3%;
}

#etape {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

#etape > div > ul > li {
  font-size: 1.5em;
  text-align: left;
  color: #07989f;
}

#listeAgence > ul > li {
  padding-bottom: 4%;
  font-size: 1.5em;
  text-align: left;
  margin-left: 5%;
  color: #07989f;
}

#titreBlue {
  text-align: left;
  color: #07989f;
  margin-top: 3%;
  margin-left: 3%;
}

#navi {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1%;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {
  h2 {
    font-size: 1em;
  }

  li {
    font-size: 1em !important;
  }

  #listeAgence > h2 {
    font-size: 1.5em;
  }

  #btnInscriptionGratuit {
    margin-top: 10%;
  }

  #imgParcours {
    width: 100%;
    margin-top: 3%;
  }

  #imgMain {
    width: 15%;
    position: relative;
  }

  #tel {
    position: relative;
    width: 30%;
    right: 10%;
  }

  #imgHeader {
    height: 70vh;
  }

  #infoAgence {
    display: flex;
    padding: 2%;
    flex-direction: column;
  }

  #textForfait {
    font-size: 1.2em;
  }

  #etape {
    display: flex;
    flex-direction: column;
  }
  #navi {
    justify-content: space-around !important;
  }
}

/* Mobile Styles */
@media only screen and (max-width: 500px) {
  h2 {
    font-size: 1em;
  }

  #navi {
    justify-content: space-around !important;
  }

  #btnInscriptionGratuit {
    margin-top: 10%;
  }

  #textForfait {
    font-size: 1.2em;
  }

  #imgParcours {
    width: 100%;
    margin-top: 3%;
  }

  #listeAgence > h2 {
    font-size: 1.5em;
  }

  #imgMain {
    width: 30%;
  }

  #imgHeader {
    height: 35vh;
  }

  li {
    font-size: 1em !important;
  }

  #infoAgence {
    display: flex;
    padding: 2%;
    flex-direction: column;
  }

  #etape {
    display: flex;
    flex-direction: column;
  }
}
</style>
