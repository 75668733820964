<template>
  <div class="trame">
    <h1>Trame</h1>
    <template v-if="findTrame">
      <br>
      <br>
      <br>
      <h1>Vous n'avez pas de trame liée à votre agence ou à ce type de véhicule (boîte auto ou manuelle)</h1>
      <br>
      <br>
      <br>
    </template>
    <template v-else>
      <table class="trame-update">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>Traité</th>
            <th>Assimilé</th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(competence, index) in trame['competences']"
            :key="index"
          >
            <template v-for="(objectif, i) in competence['objectifs']" :key="i">
              <tr>
                <th
                  v-bind:style="{ 'background-color': competence.couleur }"
                ></th>
                <td id="objTitre">{{ objectif.label }}</td>
                <td>{{ objectif.duree }}</td>
                <template v-if="objectif.objacq == 'traite'">
                  <td>
                    <label class="switch"
                      ><input
                        @click="actionObj"
                        :data-id="objectif.id"
                        type="checkbox"
                        name="traite"
                        disabled
                        checked /><span class="slider"></span
                    ></label>
                  </td>
                  <td>
                    <label class="switch"
                      ><input
                        @click="actionObj"
                        :data-id="objectif.id"
                        type="checkbox"
                        disabled
                        name="assimile" /><span class="slider"></span
                    ></label>
                  </td>
                </template>
                <template v-else-if="objectif.objacq === 'assimile'">
                  <td>
                    <label class="switch"
                      ><input
                        @click="actionObj"
                        :data-id="objectif.id"
                        type="checkbox"
                        name="traite"
                        disabled
                        checked /><span class="slider"></span
                    ></label>
                  </td>
                  <td>
                    <label class="switch"
                      ><input
                        @click="actionObj"
                        :data-id="objectif.id"
                        type="checkbox"
                        name="assimile"
                        disabled
                        checked /><span class="slider"></span
                    ></label>
                  </td>
                </template>
                <template v-else>
                  <td>
                    <label class="switch"
                      ><input
                        @click="actionObj"
                        :data-id="objectif.id"
                        type="checkbox"
                        disabled
                        name="traite" /><span class="slider"></span
                    ></label>
                  </td>
                  <td>
                    <label class="switch"
                      ><input
                        @click="actionObj"
                        :data-id="objectif.id"
                        type="checkbox"
                        disabled
                        name="assimile" /><span class="slider"></span
                    ></label>
                  </td>
                </template>
              </tr>
              <template
                v-for="(sousObjectif, idx) in objectif['sousObjectifs']"
                :key="idx"
              >
                <tr>
                  <th
                    v-bind:style="{ 'background-color': competence.couleur }"
                  ></th>
                  <td id="labelSousObj">{{ sousObjectif.label }}</td>
                  <td>{{ sousObjectif.duree }}</td>

                  <template v-if="objectif.objacq == 'traite'">
                    <td>
                      <label class="switch"
                        ><input
                          id="ssobj"
                          @click="actionSousObjectif"
                          :data-id="sousObjectif.id"
                          :data-obj-id="objectif.id"
                          type="checkbox"
                          name="traite"
                          disabled
                          checked /><span class="slider"></span
                      ></label>
                    </td>
                    <td>
                      <label class="switch"
                        ><input
                          id="ssobj"
                          @click="actionSousObjectif"
                          :data-id="sousObjectif.id"
                          :data-obj-id="objectif.id"
                          type="checkbox"
                          disabled
                          name="assimile" /><span class="slider"></span
                      ></label>
                    </td>
                  </template>
                  <template v-else-if="objectif.objacq === 'assimile'">
                    <td>
                      <label class="switch"
                        ><input
                          id="ssobj"
                          @click="actionSousObjectif"
                          :data-id="sousObjectif.id"
                          :data-obj-id="objectif.id"
                          type="checkbox"
                          name="traite"
                          disabled
                          checked /><span class="slider"></span
                      ></label>
                    </td>
                    <td>
                      <label class="switch"
                        ><input
                          id="ssobj"
                          @click="actionSousObjectif"
                          :data-id="sousObjectif.id"
                          :data-obj-id="objectif.id"
                          type="checkbox"
                          name="assimile"
                          disabled
                          checked /><span class="slider"></span
                      ></label>
                    </td>
                  </template>
                  <template v-else>
                    <template v-if="sousObjectif.acquis == 'null'">
                      <td>
                        <label class="switch"
                          ><input
                            id="ssobj"
                            @click="actionSousObjectif"
                            :data-id="sousObjectif.id"
                            :data-obj-id="objectif.id"
                            type="checkbox"
                            disabled
                            name="traite" /><span class="slider"></span
                        ></label>
                      </td>
                      <td>
                        <label class="switch"
                          ><input
                            id="ssobj"
                            @click="actionSousObjectif"
                            :data-id="sousObjectif.id"
                            :data-obj-id="objectif.id"
                            type="checkbox"
                            disabled
                            name="assimile" /><span class="slider"></span
                        ></label>
                      </td>
                    </template>
                    <template v-else>
                      <template v-if="sousObjectif.acquis[0].id == 1">
                        <td>
                          <label class="switch"
                            ><input
                              id="ssobj"
                              @click="actionSousObjectif"
                              :data-id="sousObjectif.id"
                              :data-obj-id="objectif.id"
                              type="checkbox"
                              name="traite"
                              disabled
                              checked /><span class="slider"></span
                          ></label>
                        </td>
                        <td>
                          <label class="switch"
                            ><input
                              id="ssobj"
                              @click="actionSousObjectif"
                              :data-id="sousObjectif.id"
                              :data-obj-id="objectif.id"
                              type="checkbox"
                              disabled
                              name="assimile" /><span class="slider"></span
                          ></label>
                        </td>
                      </template>
                      <template v-else>
                        <td>
                          <label class="switch"
                            ><input
                              id="ssobj"
                              @click="actionSousObjectif"
                              :data-id="sousObjectif.id"
                              :data-obj-id="objectif.id"
                              type="checkbox"
                              name="traite"
                              disabled
                              checked /><span class="slider"></span
                          ></label>
                        </td>
                        <td>
                          <label class="switch"
                            ><input
                              id="ssobj"
                              @click="actionSousObjectif"
                              :data-id="sousObjectif.id"
                              :data-obj-id="objectif.id"
                              type="checkbox"
                              name="assimile"
                              disabled
                              checked /><span class="slider"></span
                          ></label>
                        </td>
                      </template>
                    </template>
                  </template>
                </tr>
              </template>
            </template>
            <br />
            <br />
          </template>
        </tbody>
      </table>
    </template>

    <router-link to="/agence/planning"><b-button variant="outline-primary">Retourner au planning</b-button></router-link>

  </div>
</template>

<script>

export default {
  name: "Trame",
  data() {
    return {
      trame: [],
      findTrame : false,
    }
  },

  mounted() {
    this.getTrame();
  },
  methods: {

    getTrame(){
      window.api.get("/trame/"+ this.$route.params.id, {
        headers:{
          "Authorization": "Bearer " + this.$store.state.token
        },
      }).then( response => {
        if(response.data.trame == false){
          this.findTrame = true;
        }{
          this.trame = response.data.trame[0];
        }
        
      }).catch(error => {
        console.log(error)
      })
    },

  },

}
</script>

<style scoped>
table.trame-update {
  width: 90%;
  margin: auto;
}

.trame {
  background: url("../../assets/images/fond2.png");
  width: 100vw;
  min-height: 100vh;
  height: auto;
  background-size: cover;
  background-position: center, center;
}
table.trame-update thead {
  color: black;
}

#objTitre {
  font-size: 1.4em;
  font-weight: bold;
  text-align: start;
}
#labelSousObj {
  padding-left: 50px;
  text-align: start;
  font-weight: bold;
}

#trameHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 8%;
}

#btnTerminer {
  background-color: #07989f;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

input:checked + .slider {
  background-color: #07989f;
}

input:focus + .slider {
  box-shadow: 0 0 1px #07989f;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>